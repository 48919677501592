<template>
  <span class="status" :class="status">
    <mdicon :name="icon" /> {{text}}
  </span>
</template>
<script>
export default {
  props: {
    status: {
      required: true,
    },
  },
  computed: {
    // status() {
    //   return this.session.status;
    // },
    text() {
      switch (this.status) {
        case 'pending':
          return 'En attente';
        case 'prepare':
          return 'Préparation';
        case 'ready':
          return 'Prêt';
        case 'ignition':
          return 'Allumage';
        case 'cooking':
          return 'Cuisson en cours';
        case 'done':
          return 'Terminé';
        case 'abort':
          return 'Annulé';
        default:
          return this.status;
      }
    },
    icon() {
      switch (this.status) {
        case 'pending':
          return 'pauseCircleOutline';
        case 'prepare':
          return 'CircleSlice1';
        case 'ready':
          return 'CircleSlice2';
        case 'ignition':
          return 'CircleSlice3';
        case 'cooking':
          return 'CircleSlice5';
        case 'done':
          return 'checkboxMarkedCircleOutline';
        case 'abort':
          return 'cancel';
        default:
          return 'checkboxBlankCircle';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// '#833ab4', '#fd1d1d', '#fcb045'
.status {
  // border: 2px solid;
  // border-radius: 5px;
  // padding: .2em .5em;
}
.pending {
  color: gray;
}
.prepare {
  color: #fcb045;
}
.ready {
  color: #fcb045;
}
.ignition {
  color: #fcb045;
}
.cooking {
  color: #fd1d1d;
}
.done {
  color: green;
}

.abort {
  color: red;
}
</style>
