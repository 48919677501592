<template>
  <span class="trigger" :class="trigger">
    <mdicon :name="icon" /> {{text}}
  </span>
</template>
<script>
export default {
  props: {
    trigger: {
      required: true,
    },
  },
  computed: {
    text() {
      switch (this.trigger) {
        case 'manual':
          return 'Manuel';
        case 'scheduler':
          return 'Programmé';
        default:
          return this.trigger;
      }
    },
    icon() {
      switch (this.trigger) {
        case 'manual':
          return 'gestureTap';
        case 'scheduler':
          return 'clockOutline';
        default:
          return '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// '#833ab4', '#fd1d1d', '#fcb045'
.status {
  // border: 2px solid;
  // border-radius: 5px;
  // padding: .2em .5em;
}
.pending {
  color: gray;
}
.prepare {
  color: #fcb045;
}
.ready {
  color: #fcb045;
}
.ignition {
  color: #fcb045;
}
.cooking {
  color: #fd1d1d;
}
.done {
  color: green;
}
</style>
