import dayjs from 'dayjs';

export default {
  methods: {
    formatDate(date, format = 'DD/MM/YYYY') {
      return dayjs(date).format(format);
    },
    formatDateTime(date, format = 'DD/MM/YYYY HH:mm:ss') {
      return dayjs(date).format(format);
    },
    formatTime(date, format = 'HH:mm:ss') {
      return dayjs(date).format(format);
    },
    formatBytes(size) {
      // eslint-disable-next-line no-bitwise
      const e = (Math.log(size) / Math.log(1e3)) | 0;
      // eslint-disable-next-line no-restricted-properties
      return `${+(size / Math.pow(1e3, e)).toFixed(2)} ${'kMGTPEZY'[e - 1] || ''}B`;
    },
  },
};
