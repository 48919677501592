<template>
  <div class="view-sessions">
    <div class="view-header">
      <h2>Sessions</h2>
      <div>
        <button-reload @click="reload"></button-reload>
        <button class="primary"
          @click="$router.push({name:'SessionStart'})">
            <mdicon name="fire" />Lancer un préwarm!</button>
      </div>
    </div>
    <h3>Résumé du mois</h3>
    <div class="block">
      <div class="kpi-bar">
        <div class="kpi" >
          <label>Total sessions</label>
          <span>{{sessionsMetrics.count}}</span>
        </div>
        <div class="kpi" >
          <label>Urls consommées</label>
          <span>{{sessionsMetrics.done}}</span>
        </div>
        <div class="kpi" >
          <label>Bande passante</label>
          <span>{{formatBytes(sessionsMetrics.totalBytes)}}</span>
        </div>
      </div>
    </div>
    <h3>Dernières sessions</h3>
    <div class="sessions block">
      <table>
        <thead>
          <tr>
            <th>Projet</th>
            <th>Date</th>
            <th>Déclencheur</th>
            <th>Agents</th>
            <th>Urls</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in sessions" :key="s.id"
            @click="$router.push({name:'Session', params: {id: s.id}})">
            <td>{{s.project.name}}</td>
            <td>{{formatDateTime(s.date)}}</td>
            <td>
              <session-trigger :trigger="s.trigger" />
            </td>
            <td>
              <agent v-for="agent in s.settings.agents" :key="agent" :agent="agent" />
            </td>
            <td>
              {{s.metrics.count}}
            </td>
            <td>
              <session-status :status="s.status" />
              <em v-if="s.status === 'cooking'">
                ({{Math.round(100* s.metrics.done/s.metrics.count)}}%)</em>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import formatters from '@/mixins/formatters';
import SessionStatus from '../components/SessionStatus.vue';
import SessionTrigger from '../components/SessionTrigger.vue';
import ButtonReload from '../components/ButtonReload.vue';
import Agent from '../components/Agent.vue';
// import SessionSparkline from '../components/SessionSparkline.vue';

export default {
  mixins: [formatters],
  components: {
    SessionStatus,
    SessionTrigger,
    ButtonReload,
    Agent,
    // SessionSparkline,
  },
  computed: {
    ...mapState('projects', [
      'project',
    ]),
    ...mapState('sessions', [
      'sessions',
      'sessionsMetrics',
    ]),
  },
  methods: {
    ...mapActions('sessions', [
      'startSession',
      'loadSessions',
    ]),
    reload() {
      this.loadSessions(true);
    },
    async onEnter() {
      this.loadSessions();
    },
  },
  // watch: {
  //   project() {
  //     console.log(this.project);
  //     this.loadSessions(this.project.id);
  //   },
  // },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.onEnter(to.params));
  },
  beforeRouteUpdate(to, from, next) {
    this.onEnter(to.params);
    next();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.sessions {

}

</style>
